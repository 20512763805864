import { mobileCoverageLayer } from "../../../config/layers/layers";

export const mobileCoverageSubLayerIdTypes = mobileCoverageLayer.subLayers.map(
  ({ id }) => id,
);

export type MobileCoverageSubLayerIdType =
  (typeof mobileCoverageSubLayerIdTypes)[number];

// Ids for each of these layers: https://gis-maps.dev.apps.rfs.nsw.gov.au/arcgis/rest/services/Reference/AthenaMobileInfrastructure/MapServer
// These cover the sites and then 5g/4g/3g outdoor and external layers
export const mobileCoverageGisLayers = {
  mobileCoverageOptusSitesLayer: [0],
  mobileCoverageOptus5gLayer: [4, 7],
  mobileCoverageOptus4gLayer: [11, 14],
  mobileCoverageOptus3gLayer: [18, 21],
  mobileCoverageTelstraSitesLayer: [1],
  mobileCoverageTelstra5gLayer: [5, 8],
  mobileCoverageTelstra4gLayer: [12, 15],
  mobileCoverageTelstra3gLayer: [19, 22],
  mobileCoverageTpgSitesLayer: [2],
  mobileCoverageTpg5gLayer: [6, 9],
  mobileCoverageTpg4gLayer: [13, 16],
  mobileCoverageTpg3gLayer: [20, 23],
} as const satisfies Record<MobileCoverageSubLayerIdType, readonly number[]>;
