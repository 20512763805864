import PointDetailsContextPopup from "../../ui/MapRail/CoordinateConversionDrawer/PointDetailsContextPopup";
import ACTESAResources from "../ACTESAResources/ACTESAResources";
import AVLEmergency from "../AVLEmergency/AVLEmergency";
import AVLPositions from "../AVLPositions/AVLPositions";
import AccommodationFacilities from "../Accommodation/AccommodationFacilities";
import AerialImagery from "../AerialImageryLayers/AerialImagery";
import AgedCare from "../AgedCare/AgedCare";
import AircraftPositions from "../AircraftPositions/AircraftPositions";
import Airports from "../Airports/Airports";
import AlertStatusDistrictToday from "../AlertStatusDistrict/AlertStatusDistrictToday";
import AlertStatusStateToday from "../AlertStatusState/AlertStatusStateToday";
import BaseMaps from "../BaseMaps/BaseMaps";
import BurntAreaCurrentFireSeason from "../BurntAreaCurrentFireSeason/BurntAreaCurrentFireSeason";
import BurntAreaHazardReduction from "../BurntAreaHazardReduction/BurntAreaHazardReduction";
import BurntAreaPolygons from "../BurntAreaPolygons/BurntAreaPolygons";
import CommunicationTowers from "../CommunicationTowers/CommunicationTowers";
import DEAHotspots from "../DEAHotspots/DEAHotspots";
import EducationFacilities from "../EducationFacilities/EducationFacilities";
import EmergencyAlerts from "../EmergencyAlerts/EmergencyAlerts";
import EmergencyFacilities from "../EmergencyFacilities/EmergencyFacilities";
import FRNSWJurisdictionalBoundaries from "../FRNSWJurisdictionalBoundaries/FRNSWJurisdictionalBoundaries";
import FieldObsIconUnder1Hr from "../FieldObsIconUnder1Hr/FieldObsIconUnder1Hr";
import FieldObsIconUnder3Hrs from "../FieldObsIconUnder3Hrs/FieldObsIconUnder3Hrs";
import FieldObsSitRepUnder1Hr from "../FieldObsSitRep/FieldObsSitRepUnder1Hr";
import FieldObsSitRepUnder3Hrs from "../FieldObsSitRep/FieldObsSitRepUnder3Hrs";
import FieldObsWeatherUnder1Hr from "../FieldObsWeather/FieldObsWeatherUnder1Hr";
import FieldObsWeatherUnder3Hrs from "../FieldObsWeather/FieldObsWeatherUnder3Hrs";
import FireConditionsLayers from "../FireConditions/FireConditions";
import FireGroundFlightRisk from "../FireGroundFlightRiskLayer/FireGroundFlightRisk";
import FireMapper1Hr from "../FireMapper/FireMapper1Hr";
import FireMapper24Hrs from "../FireMapper/FireMapper24Hrs";
import FireMapper3Hrs from "../FireMapper/FireMapper3Hrs";
import FireTrails from "../FireTrails/FireTrails";
import FuelType from "../FuelType/FuelType";
import GovernmentRadioNetwork from "../GovernmentRadioNetwork/GovernmentRadioNetwork";
import HabitableProperties from "../HabitableProperties/HabitableProperties";
import HealthFacilities from "../HealthFacilities/HealthFacilities";
import HimawariSurfaceTemperature from "../HimawariSurfaceTemperature/HimawariSurfaceTemperature";
import InterstateIncidents from "../InterstateIncidents/InterstateIncidents";
import LandUse from "../LandUse/LandUse";
import Lightning from "../Lightning/Lightning";
import LiveTraffic from "../LiveTraffic/LiveTraffic";
import MobileBlackspots from "../MobileBlackspots/MobileBlackspots";
import MobileCoverage from "../MobileCoverage/MobileCoverage";
import NeighbourhoodSaferPlaces from "../NeighbourhoodSaferPlaces/NeighbourhoodSaferPlaces";
import OilAndGasPipelines from "../OilAndGasPipelines/OilAndGasPipelines";
import PowerInfrastructure from "../PowerInfrastructure/PowerInfrastructure";
import RFSBrigades from "../RFSBrigades/RFSBrigades";
import RFSDistricts from "../RFSDistricts/RFSDistricts";
import RartStandbyToday from "../Rart/RartStandbyToday";
import RedMap from "../RedMap/RedMap";
import RtaIncidents from "../RtaIncidents/RtaIncidents";
import SiteInformation from "../SiteInformation/SiteInformation";
import SocialVulnerabilityAggregation from "../SocialVulnerabilityAggregation/SocialVulnerabilityAggregation";
import StateBorder from "../StateBorder/StateBorder";
import StateForestPlantation from "../StateForestPlantation/StateForestPlantation";
import TelephoneExchange from "../TelephoneExchange/TelephoneExchange";
import TotalFireBanAreas from "../TotalFireBanAreas/TotalFireBanAreas";
import TotalFuelLoad from "../TotalFuelLoad/TotalFuelLoad";
import WildfireHistory from "../WildfireHistory/WildfireHistory";

interface CommonMapLayersProps {
  isCopView?: boolean;
}

const CommonMapLayers = ({ isCopView = false }: CommonMapLayersProps) => {
  return (
    <>
      <PointDetailsContextPopup />
      <ACTESAResources />
      <AVLEmergency isCopView={isCopView} />
      <AVLPositions isCopView={isCopView} />
      <AccommodationFacilities />
      <AerialImagery />
      <AgedCare />
      <AircraftPositions isCopView={isCopView} />
      <Airports />
      <BaseMaps />
      <BurntAreaCurrentFireSeason />
      <BurntAreaHazardReduction />
      <BurntAreaPolygons />
      <StateBorder />
      <CommunicationTowers />
      <DEAHotspots />
      <EducationFacilities />
      <EmergencyAlerts />
      <EmergencyFacilities />
      <FieldObsIconUnder3Hrs />
      <FieldObsIconUnder1Hr />
      <FieldObsSitRepUnder1Hr />
      <FieldObsSitRepUnder3Hrs />
      <FieldObsWeatherUnder1Hr />
      <FieldObsWeatherUnder3Hrs />
      <FireConditionsLayers />
      <FireGroundFlightRisk />
      <FireMapper1Hr />
      <FireMapper3Hrs />
      <FireMapper24Hrs />
      <FireTrails />
      <FuelType />
      <GovernmentRadioNetwork />
      <HabitableProperties />
      <HealthFacilities />
      <HimawariSurfaceTemperature />
      <InterstateIncidents />
      <LandUse />
      <Lightning />
      <LiveTraffic />
      <MobileBlackspots />
      <MobileCoverage />
      <NeighbourhoodSaferPlaces />
      <RartStandbyToday />
      <AlertStatusDistrictToday />
      <AlertStatusStateToday />
      <FRNSWJurisdictionalBoundaries />
      <OilAndGasPipelines />
      <PowerInfrastructure />
      <RedMap />
      <RFSBrigades />
      <RFSDistricts />
      <SiteInformation />
      <RtaIncidents />
      <SocialVulnerabilityAggregation />
      <StateForestPlantation />
      <TelephoneExchange />
      <TotalFireBanAreas />
      <TotalFuelLoad />
      <WildfireHistory />
    </>
  );
};

export default CommonMapLayers;
